import BackendApiService from './BackendApiService';
import {changeUserData} from '../store/dispatchers/app';
import {endpoints} from '../config'

class AuthService {
    static async login() {
        try {
            const sessionData = await BackendApiService.getSessionData();
            if (sessionData) {
                sessionStorage.setItem('logged', '1');
                sessionStorage.setItem('session-data', JSON.stringify(sessionData));
                changeUserData(sessionData);
                return true;
            }
        }
        catch (error) {
            console.error(error);
        }
        this.logout();
        return false;
    }

    static async logout() {
        sessionStorage.clear();
        await BackendApiService.doLogout(endpoints.autofactProApi.domain);
        changeUserData({});
        return true;
    }

    static async getAuthData() {
        const logged = sessionStorage.getItem('logged');
        const sessionData = sessionStorage.getItem('session-data');
        if (logged && sessionData) {
            changeUserData(JSON.parse(sessionData));
            return JSON.parse(sessionData);
        }
        return false;
    }

    static canDo(permission) {
        const sessionData = sessionStorage.getItem('session-data');
        if (sessionData && permission) {
            const sessionObject = JSON.parse(sessionData);
            return Object.values(sessionObject.usuario.body.permisos_uri).includes(permission);
        }
        return true;
    }
}

export default AuthService;
