import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from 'reactstrap';
import moment from 'moment';

import DataTable from '../data-table/DataTable';
import BillingStatus from '../../services/BillingStatus';
import PurchaseOrderHelper from '../../helpers/PurchaseOrderHelper';
import BackendApiService from '../../services/BackendApiService';
import moneyFormat from '../../helpers/MoneyFormat';
import UploadOC from '../../components/upload-oc/UploadOC';
import SeeOCInfo from '../../components/see-oc-info/SeeOCInfo';
import ShowDraft from '../../components/show-draft/ShowDraft';
import NotifiedPayment from '../../components/notified-payment/NotifiedPayment';
import ShowDetails from '../../components/details/ShowDetails';
import CustomTooltip from '../../helpers/Tooltip';
import { Redirect } from 'react-router-dom'
import { FaMoneyCheck } from 'react-icons/fa';


class InvoiceCenterBillingList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
        this.state = {
            showDetails: false,
            currentItem: {},
            redirectToPayment: false,
            userData: props.userData,
            modalUploadOCIsOpen: false,
            modalSeeOCIsOpen: false,
            modalShowDraftIsOpen: false,
            modalNotifiedPaymentIsOpen: false,
            uploadingOC: false,
            paymentDocOC: {},
            paymentDocId: '',
            paymentDocsSelected: {},
            sendingNotification: false,
            alertNotifyVisible: true,
        }
    }

    reloadData = () => {
        this.dataTableRef.current.reloadData();
    }

    renderBillingType(item) {
        let prefixInvoiceKind = 'FACT. ';
        switch (item.tipo_documento_pago) {
            case 'NOTA CREDITO': prefixInvoiceKind = 'NC. '; break;
            case 'NOTA DEBITO': prefixInvoiceKind = 'ND. '; break;
            default: prefixInvoiceKind = 'FACT. '
        }

        switch (item.tipo_exencion_impuesto) {
            case 'AFECTA':
                return <span className="closure-type bg-primary text-white">{prefixInvoiceKind + item.tipo_exencion_impuesto}</span>
            case 'EXENTA':
                return <span className="closure-type bg-primary-dark text-white">{prefixInvoiceKind + item.tipo_exencion_impuesto}</span>
            default:
                return <span>{item.tipo_exencion_impuesto}</span>
        }
    }
    renderTaxIdentifier(item) {
        if (item.identificador_tributario) {
            return item.identificador_tributario.replace(/.*?-/, '')
        } else
            return '-';
    }

    renderBillingStatus(item) {
        let classes = '';
        if (item.estado === BillingStatus.PENDING && !item.isLate) {
            classes = 'bg-pending';
        } else if ((item.estado === BillingStatus.PENDING || item.estado === BillingStatus.LATE) && item.isLate) {
            classes = 'bg-red text-white';
            item.estado = BillingStatus.LATE;
        } else if (item.estado === BillingStatus.PAID) {
            classes = 'bg-paid';
        } else if (item.estado === BillingStatus.TO_CONFIRM) {
            classes = 'bg-to-confirm';
        } else if (item.estado === BillingStatus.CREATED) {
            classes = 'bg-created';
        }
        return <span className={`${classes} closure-status`}>{BillingStatus.getName(item.estado)}</span>;
    }

    renderEndDate(item) {
        const _date = moment(item.timestamp_end);
        if (_date.isValid()) {
            return <span className="table-text">{_date.format('DD-MM-YYYY')}</span>
        }
        return null;
    }

    renderStartDate(item) {
        const _date = moment(item.timestamp_emision);
        if (_date.isValid()) {
            return <span className="table-text">{_date.format('DD-MM-YYYY')}</span>
        }
        return null;
    }

    renderValue(item) {
        return <span className={`table-text text-center`}>${moneyFormat(item.total)}</span>
    }

    renderAmmount(item) {
        const signed = item.tipo_documento_pago === 'NOTA CREDITO' ? '-' : '';
        if (item.estado === 'PAID') {
            return <span className={`table-text text-center`}>{signed}${moneyFormat(0)}</span>
        }
        return <span className={`table-text text-center`}>{signed}${moneyFormat(item.total - item.total_pagado || 0)}</span>
    }

    renderInvoiceCenter(item) {
        return <span className={`table-text text-center`}>{item.nombre}</span>
    }

    renderInvoiceCenterShortName(item) {
        return <span className={`table-text text-center`}>{item.nombre_corto}</span>
    }

    renderCheckBox(item) {
        return <input type="checkbox" className="form-control-sm" style={{ width: 17 }}
            onChange={(e) => this.onChangeChecked(e, item)} value={item.iddocumento_pago}
            checked={this.state.paymentDocsSelected.hasOwnProperty(item.iddocumento_pago)}>
        </input>
    }

    renderOCColumn(item) {
        const allowUploadOc = item.total_sin_impuesto > item.suma_total_orden_compra;
        const ocButtons = [];
        if (item.cf_orden_compra === true && item.estado === BillingStatus.CREATED) {
            if (allowUploadOc) {
                const uploadOCBtn = <button key="oc" className="btn btn-sm btn-oc btn-bg-blue-white"
                    onClick={(e) => this.openModalUploadOC(e, item)}>SUBIR O.C.</button>;
                ocButtons.push(uploadOCBtn);
            }
            if (item.numeros_orden_compra) {
                const seeOCBtn = <button key="see_oc" className={`btn btn-sm btn-oc btn-bg-white-blue ${allowUploadOc ? "mt-1" : ''}`}
                    onClick={(e) => this.openModalSeeOC(e, item)}>VER O.C.</button>
                ocButtons.push(seeOCBtn);
            }
        }
        return ocButtons;
    }

    onChangeChecked = (e, item) => {
        const docsSelected = Object.assign({}, this.state.paymentDocsSelected);
        if (e.target.checked) {
            if ([BillingStatus.PENDING, BillingStatus.LATE].includes(item.estado)) {
                docsSelected[item.iddocumento_pago] = item;
            }
        } else {
            delete docsSelected[item.iddocumento_pago];
        }
        this.setState({ paymentDocsSelected: docsSelected });
    }

    async showModalDetails(e, item) {
        e.preventDefault();
        console.log('item', item);
        this.setState({ showDetails: true, currentItem: item });
    }

    closeModalDetails = () => {
        this.setState({ showDetails: false });
    }

    showDraftButton = (item) => {
        return item.estado === BillingStatus.CREATED && item.cf_orden_compra === true;
    }

    // upload purchase order
    openModalUploadOC(e, item) {
        e.preventDefault();
        this.setState({ modalUploadOCIsOpen: true, paymentDocOC: item });
    }
    openModalSeeOC = (e, item) => {
        e.preventDefault();
        this.setState({ modalSeeOCIsOpen: true, paymentDocOC: item });
    }
    closeModalUploadOC = () => {
        this.setState({ modalUploadOCIsOpen: false });
    }
    closeModalSeeOCInfo = () => {
        this.reloadData();
        this.setState({ modalSeeOCIsOpen: false });
    }

    saveUploadedOC = async (ocFormData) => {
        this.setState({ uploadingOC: true });
        const paymentDocRelated = this.state.paymentDocOC;
        try {
            let s3Url = null;
            if (ocFormData.ocFile) {
                const pathParams = {
                    invoiceCenterCountryCode: paymentDocRelated.codigo_pais,
                    invoiceCenterId: paymentDocRelated.idcentro_facturacion
                };
                const path = PurchaseOrderHelper.buildFilePath(pathParams);
                const base64File = await this.getBase64(ocFormData.ocFile);
                const uploadParams = {
                    path, fileName: ocFormData.ocFileName, fileDataBase64: base64File
                }
                s3Url = await PurchaseOrderHelper.uploadToS3(uploadParams);
            }

            if ((ocFormData.ocFile && s3Url) || !ocFormData.ocFile) {
                const assignOCParams = {
                    deliveredDate: ocFormData.ocDate,
                    legalIdentifier: ocFormData.ocNumber,
                    total: parseInt(ocFormData.ocAmount),
                    consolidateId: paymentDocRelated.consolidado_idconsolidado,
                    invoiceCenterId: paymentDocRelated.idcentro_facturacion,
                    paymentDocId: paymentDocRelated.iddocumento_pago,
                    urlPdf: s3Url
                }
                const response = await BackendApiService.assignPurchaseOrder(assignOCParams);
                if (response.status === 'OK') {
                    alert('Se subió la Orden de Compra satisfactoriamente');
                    this.setState({ uploadingOC: false, paymentDocOC: {}, modalUploadOCIsOpen: false });
                    this.reloadData();
                    // async send notification
                    this._sendOCNotification(ocFormData, s3Url, paymentDocRelated.iddocumento_pago);
                    return true;
                }
            }
            alert('Ocurrió un error subiendo la Orden de Compra');
        } catch (e) {
            console.error(e);
            alert('Ocurrió un error subiendo la Orden de Compra');
        }
        this.setState({ uploadingOC: false, paymentDocOC: {} });
    }
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    _sendOCNotification = async (formData, s3Url, paymentDocId) => {
        const userData = this._getUserData(this.props.userData);
        const params = {
            ocNumber: `801-${formData.ocNumber}`, ocDate: formData.ocDate, ocUrl: s3Url,
            socialReason: userData.socialReason,
            paymentDocId: paymentDocId,
            fromEmail: userData.email
        }
        const response = await BackendApiService.sendEmailOCNotification(params);
        response ? console.log('notification send OK') : console.log('error sending notification');
    }

    // show modal with draft info
    showDraftModal = (e, item) => {
        e.preventDefault();
        this.setState({ modalShowDraftIsOpen: true, paymentDocId: item.iddocumento_pago });
    }
    closeModalShowDraft = () => {
        this.setState({ modalShowDraftIsOpen: false });
    }

    paymentRedirect = (e) => {
        if (this.state.redirectToPayment) {
            return <Redirect to={{
                pathname: '/facturas/pago',
                state: { data: this.state.paymentDocsSelected }
            }} />
        }
    }

    sendToPayment = (e) => {
        this.setState({ redirectToPayment: true })
    }

    // notified payment
    openNotifiedPaymentModal = (e) => {
        e.preventDefault();
        this.setState({ modalNotifiedPaymentIsOpen: true });
    }
    closeModalNotifiedPayment = () => {
        this.setState({ modalNotifiedPaymentIsOpen: false });
    }
    notifiedPayment = async (formData) => {
        try {
            this.setState({ sendingNotification: true });
            const docsIds = Object.keys(this.state.paymentDocsSelected).map(id => parseInt(id));
            const changeStatusParams = { paymentDocsIds: docsIds, status: BillingStatus.TO_CONFIRM };
            // change selected invoices status
            const changeStatusResponse = await BackendApiService.changePaymentDocStatus(changeStatusParams);
            if (changeStatusResponse.status === 'OK') {
                const sendNotificationResponse = await this._sendEmailPaymentNotification(formData);
                if (sendNotificationResponse) {
                    this.setState({ modalNotifiedPaymentIsOpen: false, sendingNotification: false, paymentDocsSelected: {} })
                    alert('Fue enviada la notificación de pago satisfactoriamente');
                    this.reloadData();
                    return true;
                }
            }

            alert('Ocurrió un error enviando la notificación de pago');
            this.setState({ sendingNotification: false })
        } catch (error) {
            console.error(error);
            alert('Ocurrió un error enviando la notificación de pago');
        }
    }
    /**
     * Send email about payment notification of invoices
     * @param formData payment form data
     */
    _sendEmailPaymentNotification = async (formData) => {
        const { socialReason } = this._getUserData(this.props.userData);
        const { amount } = formData;
        const docsSelected = Object.values(this.state.paymentDocsSelected);
        let sumTotals = 0;
        const invoicesData = docsSelected.map(doc => {
            sumTotals += doc.total;
            const mapped = {};
            mapped.docLegalIdentifier = doc.identificador_tributario;
            mapped.status = BillingStatus.getName(BillingStatus.TO_CONFIRM);
            mapped.emissionDate = doc.timestamp_emision;
            mapped.totalDoc = `$${moneyFormat(doc.total)}`;
            return mapped;
        })
        const beautifyAmount = `$${moneyFormat(amount.replace(/\./g, ''))}`;
        const emailNotificationParams = formData;
        const extraParams = {
            amount: beautifyAmount, invoicesData, socialReason, sumTotals: `$${moneyFormat(sumTotals)}`
        }
        return await BackendApiService.sendEmailPaymentNotification(Object.assign(emailNotificationParams, extraParams));
    }
    _getUserData = (userData) => {
        try {
            const email = userData.usuario.body.mail;
            const socialReason = userData.usuario.body.centro_facturacion.body.nombre;
            return { email, socialReason };
        } catch (e) {
            console.log(e);
            return {};
        }
    }

    onDismissNotifyAlert = () => {
        this.setState({
            alertNotifyVisible: false,
        })
    }

    render() {
        const columns = [
            {
                Header: '',
                width: 30,
                Cell: row => {
                    return this.renderCheckBox(row.original)
                }
            },
            {
                Header: 'Tipo de documento',
                accessor: 'tipo_documento_pago',
                headerClassName: 'wordwrap',
                width: 110,
                Cell: row => {
                    return this.renderBillingType(row.original)
                }
            },
            {
                Header: 'Folio SII',
                accessor: 'identificador_tributario',
                className: `table-text text-center`,
                headerClassName: 'wordwrap',
                width: 70,
                Cell: row => {
                    return this.renderTaxIdentifier(row.original)
                }
            }, {
                Header: 'Centro de facturación',
                accessor: 'type',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return this.renderInvoiceCenter(row.original)
                }
            }, {
                Header: 'Sucursal',
                accessor: 'type',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return this.renderInvoiceCenterShortName(row.original)
                }
            },
            {
                Header: 'Fecha de emisión',
                accessor: 'timestamp_emision',
                headerClassName: 'wordwrap',
                width: 95,
                Cell: row => {
                    return this.renderStartDate(row.original)
                }
            },
            {
                Header: 'Fecha de vencimiento',
                accessor: 'timestamp_emision',
                headerClassName: 'wordwrap',
                width: 95,
                Cell: row => {
                    return this.renderEndDate(row.original)
                }
            },
            {
                Header: 'Valor',
                accessor: 'total_sin_impuesto',
                headerClassName: 'wordwrap',
                width: 80,
                Cell: row => {
                    return this.renderValue(row.original)
                }
            },
            {
                Header: 'Saldo',
                accessor: 'impuesto',
                headerClassName: 'wordwrap',
                width: 80,
                Cell: row => {
                    return this.renderAmmount(row.original)
                }
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                headerClassName: 'wordwrap',
                width: 120,
                Cell: row => {
                    return this.renderBillingStatus(row.original)
                }
            },
            {
                Header: 'Ver',
                accessor: 'total',
                width: 210,
                Cell: row => {
                    const buttons = [
                        <button key="detail" className={`btn btn-sm btn-outline-detail`} onClick={(e) => { this.showModalDetails(e, row.original) }}>VER DETALLE</button>
                    ];
                    if (row.original.url_pdf_documento) {
                        buttons.push(<a key="billing" className={`btn btn-sm btn-fill-detail ml-2`} href={row.original.url_pdf_documento}>VER FACTURA</a>)
                    }
                    if (this.showDraftButton(row.original)) {
                        buttons.push(<button key="draft" className={`btn btn-sm btn-fill-detail ml-2`}
                            onClick={(e) => this.showDraftModal(e, row.original)}>VER BORRADOR</button>)
                    }
                    return buttons;
                }
            },
            {
                Header: 'Orden de compra',
                accessor: 'oc',
                className: "text-center d-flex flex-column",
                headerClassName: 'wordwrap',
                width: 100,
                Cell: row => {
                    return this.renderOCColumn(row.original);
                }
            }
        ];
        
        const paymentPermission = Object.values(this.state.userData.usuario.body.permisos_uri).includes('aas:cobranza.pagar_online');
        return (
            <div className={`invoice-center-closure-list-wrapper tab-pane`}>
                {this.state.showDetails && <ShowDetails
                    item={this.state.currentItem}
                    className='cobranza-modal draft'
                    closeDialog={this.closeModalDetails}
                />}
                {this.paymentRedirect()}
                {
                    this.state.modalUploadOCIsOpen &&
                    <UploadOC
                        closeModalHandler={this.closeModalUploadOC}
                        uploadOCHandler={this.saveUploadedOC}
                        paymentDocRelated={this.state.paymentDocOC}
                        loading={this.state.uploadingOC}
                    />
                }
                {
                    this.state.modalSeeOCIsOpen &&
                    <SeeOCInfo
                        closeModalHandler={this.closeModalSeeOCInfo}
                        paymentDocId={this.state.paymentDocOC.iddocumento_pago}
                        reloadTableHandler={this.reloadData}
                    />
                }
                {
                    this.state.modalShowDraftIsOpen &&
                    <ShowDraft
                        closeModalHandler={this.closeModalShowDraft}
                        paymentDocId={this.state.paymentDocId}
                    />
                }
                {
                    this.state.modalNotifiedPaymentIsOpen &&
                    <NotifiedPayment
                        closeModalHandler={this.closeModalNotifiedPayment}
                        notifiedPaymentHandler={this.notifiedPayment}
                        loading={this.state.sendingNotification}
                    />
                }
                <div className="invoice-center-billing invoices content-buttons">
                    {paymentPermission
                        &&
                        <Button disabled={Object.keys(this.state.paymentDocsSelected).length === 0}
                            onClick={this.sendToPayment}
                            className="btn btn-sm btn-primary-dark btn btn-secondary btn-payment invoices">
                            <FaMoneyCheck className="icon" />&nbsp;&nbsp;Pagar
                    </Button>
                    }
                    <CustomTooltip content="Selecciona las facturas que deseas notificar" placement="bottom">
                        <span  className="notifyButton">
                            <Button disabled={Object.keys(this.state.paymentDocsSelected).length === 0}
                                onClick={this.openNotifiedPaymentModal}
                                style={Object.keys(this.state.paymentDocsSelected).length === 0 ? { pointerEvents: "none" } : {}}
                                className="btn btn-sm btn-primary-dark btn btn-secondary btn-notified-payment invoices">
                                Notificar pago
                            </Button>
                        </span>
                    </CustomTooltip>

                </div>
                <hr></hr>
                <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    columns={columns} {...this.props}
                />
            </div>
        );
    }
}

export default connect(store => ({
    userData: store.app.userData
}), null, null, { withRef: true })(InvoiceCenterBillingList);
